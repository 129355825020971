.multi-select {
      display: block;
      font-size: 16px;
      padding: 10px;
      width: 200px;
      border: 2px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      color: #333;
    }
    
    .multi-select option {
      font-size: 16px;
      padding: 10px;
      background-color: #fff;
      color: #333;
    }
    
    .multi-select option:checked {
      background-color: #007bff;
      color: #fff;
    }